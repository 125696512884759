var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-7 col-lg-10"},[_c('div',{staticClass:"card shadow border-0 mt-5 mb-5"},[_c('div',{staticClass:"card-body px-lg-5 py-lg-5"},[_c('div',{staticClass:"text-center h1 mb-4"},[_vm._v("Contact via")]),_c('div',{staticClass:"row contact-row"},[_c('base-button',{staticClass:"col contact-whatsapp shinner",attrs:{"tag":"a","role":"button","target":"_blank","href":"https://wa.me/94757871494"}},[_c('i',{staticClass:"fa fa-3x fa-whatsapp"})]),_c('base-button',{staticClass:"col contact-telegram shinner",attrs:{"tag":"a","role":"button","target":"_blank","href":"https://t.me/nuwan94"}},[_c('i',{staticClass:"fa fa-3x fa-telegram"})]),_c('base-button',{staticClass:"col contact-skype shinner",attrs:{"tag":"a","role":"button","target":"_blank","href":"skype:live:nuwanalawatta"}},[_c('i',{staticClass:"fa fa-3x fa-skype"})])],1),_c('div',{staticClass:"text-center h2 mt-4 mb-2"},[_vm._v("or")]),_c('div',{staticClass:"text-center h1 mt-2 mb-4"},[_vm._v("Send a Message")]),_c('form',{key:"0",ref:"contactForm",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('base-input',{attrs:{"dark":"true","required":"","alternative":"","label":"Name","input-classes":"form-control-alternative bg-dark text-white","value":_vm._f("autoCapitalize")(_vm.name),"error":_vm.$v.name.$dirty && _vm.$v.name.$error
                    ? !_vm.$v.name.required
                      ? 'Name is required.'
                      : "Name should consist atleast first and last name."
                    : undefined,"valid":!_vm.$v.name.$invalid && _vm.$v.name.$dirty ? true : undefined},on:{"blur":function () { return _vm.$v.name.$touch(); },"input":function (v) { return _vm.setName(v); }}}),_c('base-input',{attrs:{"dark":"true","required":"","alternative":"","label":"Email","input-classes":"form-control-alternative  bg-dark text-white","value":_vm.mail,"error":_vm.$v.mail.$dirty && _vm.$v.mail.$error
                    ? !_vm.$v.mail.required
                      ? 'Email is required.'
                      : "Invalid email format."
                    : undefined,"valid":!_vm.$v.mail.$invalid && _vm.$v.mail.$dirty ? true : undefined},on:{"blur":function () { return _vm.$v.mail.$touch(); },"input":function (v) { return _vm.setEmail(v); }}}),_c('base-input',{attrs:{"required":"","alternative":"","label":"Message","value":_vm.message,"error":_vm.$v.message.$dirty && _vm.$v.message.$error
                    ? !_vm.$v.message.required
                      ? 'Message is required.'
                      : ("Your message should have at least " + (_vm.$v.message.$params.minWords.count -
                          _vm.countWord(_vm.message)) + " more words.")
                    : undefined,"valid":!_vm.$v.message.$invalid && _vm.$v.message.$dirty ? true : undefined}},[_c('textarea',{staticClass:"form-control form-control-alternative bg-dark text-white",attrs:{"rows":"3"},domProps:{"value":_vm.message},on:{"blur":function () { return _vm.$v.message.$touch(); },"input":function (v) { return _vm.setMessage(v.target.value); }}})]),_c('base-button',{staticClass:"button p-3",attrs:{"type":"primary","nativeType":"submit","block":"","disabled":_vm.submitStatus === 'PENDING'}},[_vm._v("Submit")])],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }