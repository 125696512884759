<template>
  <stats-card
    title="HackTheBox"
    type="gradient-dark"
    sub-title="Security Learning"
    icon="nsa94-hackthebox"
    url="https://app.hackthebox.eu/profile/159870"
    class="mb-4 mb-xl-0"
  >
    <template slot="footer">
      <a href="https://app.hackthebox.eu/profile/159870" target="_blank">
        <img
          height="65"
          src="https://www.hackthebox.eu/badge/image/159870"
          alt="Hack The Box"
        />
      </a>
    </template>
  </stats-card>
</template>

<script>
export default {};
</script>
