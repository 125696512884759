var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper h-100",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{staticClass:"mobile-navbar bg-dark",attrs:{"background-color":_vm.sidebarBackground,"short-title":"Nuwan","title":"Nuwan","logo":"Nuwan"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Profile',
          icon: 'ni ni-circle-08 text-primary',
          path: '/profile',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Highlights',
          icon: 'ni ni-chart-pie-35 text-red',
          path: '/stats',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Education',
          icon: 'ni ni-books text-green',
          path: '/education',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Experience',
          icon: 'ni ni-briefcase-24 text-orange',
          path: '/experience',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Portfolio',
          icon: 'ni ni-app text-blue',
          path: '/portfolio',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contact',
          icon: 'ni ni-send text-yellow',
          path: '/contact',
        }}})],1)],2),_c('div',{staticClass:"main-content pb-5 h-100",attrs:{"data":_vm.sidebarBackground}},[_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }