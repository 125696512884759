<template>
  <div class="card shadow">
    <div class="card-header bg-transparent border-0 pb-0">
      <h3 class="mb-0">GitHub Contributions</h3>
    </div>
    <div class="card-body text-center">
      <img
        class="img-fluid mb-5"
        src="https://github-readme-stats.vercel.app/api/?username=nuwan94"
        alt="GitHub Stats"
        style="filter: hue-rotate(245deg)"
      />

      <img
        class="img-fluid"
        src="https://ghchart.rshah.org/nuwan94"
        alt="GitHub Contribution Graph"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>